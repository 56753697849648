import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        email: '',
        phone: ''
    });

    const [formValid, setFormValid] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the popup

    const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeYRxM1M2qkUbpnZIdrWhyHJrj9PJ641vFIlgoq0r_DOBigbw/formResponse';

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        // Validate form fields
        validateForm(name, value);
    };

    // Validate the form
    const validateForm = (name, value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;
        let newErrors = { ...errors };

        if (name === 'email') {
            if (value && !emailRegex.test(value)) {  // Only validate email if it's not empty
                newErrors.email = 'Please enter a valid email address.';
            } else {
                newErrors.email = '';
            }
        }

        if (name === 'phone') {
            if (!phoneRegex.test(value)) {
                newErrors.phone = 'Phone number must be 10 digits.';
            } else {
                newErrors.phone = '';
            }
        }

        setErrors(newErrors);

        // Check if the form is valid (phone is required, email is optional but if provided, should be valid)
        const isFormValid = formData.name && phoneRegex.test(formData.phone);
        setFormValid(isFormValid);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formValid) {
            const formURL = `${GOOGLE_FORM_URL}?entry.1659917404=${encodeURIComponent(formData.name)}&entry.941155638=${encodeURIComponent(formData.email)}&entry.1895354621=${encodeURIComponent(formData.phone)}&entry.351447473=${encodeURIComponent(formData.message)}`;

            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = formURL;
            document.body.appendChild(iframe);

            // Show the success popup
            setShowPopup(true);
        } else {
            alert('Please fill out all fields correctly.');
        }
    };

    // Close the popup
    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
        <section className='contact'>
        <div className="contact-prefix-container">
           
            <div className="contact-prefix-form">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email (Optional)"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <p className="contact-prefix-error">{errors.email}</p>}
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Your Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength="10"
                        required
                    />
                    {errors.phone && <p className="contact-prefix-error">{errors.phone}</p>}
                    <textarea
                        name="message"
                        placeholder="Send us a message (optional)"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    <button type="submit" disabled={!formValid}>
                        Send Message
                    </button>
                </form>
            </div>
            <div className="contact-prefix-info">
                <div className="contact-prefix-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3770.7193048852487!2d73.0982585!3d19.0760751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7eb0043d33ba9%3A0xcf671d0014bac55!2sHB%20Realty%20-%20The%20Gateway!5e0!3m2!1sen!2sin!4v1726481518484!5m2!1sen!2sin"
                        width="100%"
                        height="200"
                        allowFullScreen=""
                        loading="lazy"
                        title="Location Map"
                    />


                    <div className="contact-prefix-company-details">
                        <h3>Our Location</h3>
                        <p>The Gateway</p>
                        <p>Survey No. 116/2B, Near Taloja Phase 2, At Ghot Road,Panvel, Raigarh, Navi Mumboi-410208</p>
                        <p>Phone: +91 9967635341</p>
                        <p>Email: talojathegateway@gmail.com</p>
                    </div>
                </div>
            </div>

            {showPopup && (
                <div className="contact-prefix-success-popup">
                    <div className="contact-prefix-popup-content">
                        <span className="contact-prefix-close-btn" onClick={closePopup}>&times;</span>
                        <h3>Message Received!</h3>
                        <p>We have received your message. We will get in touch with you shortly.</p>
                    </div>
                </div>
            )}
        </div>
        </section>
        </>
    );
};

export default Contact;