import React, { useState } from 'react';
import './DownloadPopup.css';
import brochurepdf from '../src/asset/TheGateway-FinalBrochure.pdf';

const DownloadPopup = ({ onClose }) => {
    const [name, setName] = useState(''); // State for the name field
    const [phone, setPhone] = useState('');
    const [formValid, setFormValid] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeYRxM1M2qkUbpnZIdrWhyHJrj9PJ641vFIlgoq0r_DOBigbw/formResponse';

    const validatePhone = (value) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(value);
    };

    const validateForm = (nameValue, phoneValue) => {
        // Form is valid if the name is not empty and the phone number is valid
        return nameValue.trim() !== '' && validatePhone(phoneValue);
    };

    const handleNameChange = (e) => {
        const nameValue = e.target.value;
        setName(nameValue);
        setFormValid(validateForm(nameValue, phone)); // Check form validity on name change
    };

    const handlePhoneChange = (e) => {
        const phoneValue = e.target.value;
        setPhone(phoneValue);
        setFormValid(validateForm(name, phoneValue)); // Check form validity on phone change
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form from submitting and reloading the page
        if (formValid) {
            const formURL = `${GOOGLE_FORM_URL}?entry.1659917404=${encodeURIComponent(name)}&entry.1895354621=${encodeURIComponent(phone)}&entry.351447473=Brochure downloaded`;

            // Create a hidden iframe to submit the Google Form
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = formURL;
            document.body.appendChild(iframe);

            setSubmitted(true); // Indicate that the form has been submitted
        } else {
            alert('Please fill out both fields correctly.');
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = brochurepdf;
        link.download = 'The Gateway - Final Brochure.pdf'; // Name of the file to download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h2 style={{
  fontSize: '1.2rem', /* Adjust the size as needed */
  fontWeight: 'normal', /* Optional: Adjust the font weight */
  color: '#000000', /* Optional: Change text color */
  margin: '0', /* Remove default margin */
  padding: '10px', /* Add some padding if needed */
  textAlign: 'center' /* Center align the text */
}}>
  Please enter your name and number to download:
</h2>

                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={handleNameChange}
                        required
                    />
                    <input
                        type="tel"
                        placeholder="Your Phone Number"
                        value={phone}
                        onChange={handlePhoneChange}
                        maxLength="10" // Phone number still has a limit
                        required
                    />
                    {/* Conditionally render the button */}
                    {submitted ? (
                        <button type="button" onClick={handleDownload}>
                            Download Brochure Now
                        </button>
                    ) : (
                        <button type="submit" disabled={!formValid}>
                            Submit
                        </button>
                    )}
                </form>
            </div>
        </div>
    );
};

export default DownloadPopup;