import React from 'react';
import './Amenities.css';
import indoor from '../src/asset/indoor games.jpg';
import community from '../src/asset/community.jpg';
import wfh from '../src/asset/wfh.jpg';
import fitness from '../src/asset/fitness.jpg';
import play from '../src/asset/playground.jpg';
import old from '../src/asset/old.jpg';
import yoga from '../src/asset/yoga.jpg';
import court from '../src/asset/court.jpg';

const Amenities = () => {
  return (
    <section id="amenities">
      <div>
        <h1 className="section-heading" style={{
          fontFamily: "'Georgia', 'Times New Roman', serif", 
          fontSize: '32px', 
          fontWeight: 'bold', 
          color: '#873e23', 
          textAlign: 'center', 
          letterSpacing: '1px', 
          marginBottom: '15px' 
        }}>
          Amenities
          <span style={{
            display: 'block',
            width: '160px',
            height: '2px',
            backgroundColor: '#d1b584',
            margin: '10px auto 0',
            borderRadius: '2px'
          }}></span>
        </h1>
        <div className="enterprise-subservice-card-div-container">
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={community} alt="Community Center" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Community Center</h2>
              <p className="enterprise-subservice-card-div-description">
                Our Community Center is a dynamic space for events, classes, and social gatherings. It offers modern amenities and versatile rooms designed to foster community engagement and enrichment.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={fitness} alt="Fitness Center" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Fitness Center</h2>
              <p className="enterprise-subservice-card-div-description">
                Our Fitness Center is equipped with state-of-the-art exercise equipment and facilities. It provides a motivating environment for achieving your fitness goals, with options for individual workouts or group classes.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={wfh} alt="Workstation for WFH" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Workstation for WFH</h2>
              <p className="enterprise-subservice-card-div-description">
                A well-equipped, ergonomic workstation for remote work, offering comfort, ample desk space, and high-speed connectivity to boost productivity and efficiency at home.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={indoor} alt="Indoor Games" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Indoor Games</h2>
              <p className="enterprise-subservice-card-div-description">
                A versatile space designed for relaxation and fun, featuring a variety of games and activities to enjoy indoors, perfect for leisure and socializing.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={play} alt="Children's Play Area" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Children's Play Area</h2>
              <p className="enterprise-subservice-card-div-description">
                A fun and safe environment designed for children, featuring various play equipment and activities to ensure enjoyable and engaging playtime.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={old} alt="Senior Citizen Sit Out" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Senior Citizen Sit Out</h2>
              <p className="enterprise-subservice-card-div-description">
                A relaxing and comfortable space specifically designed for senior citizens, offering a peaceful environment for rest and social interaction.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={yoga} alt="Yoga/Meditation Deck" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Yoga/Meditation Deck</h2>
              <p className="enterprise-subservice-card-div-description">
                A serene and tranquil space designed for yoga and meditation practices, providing an ideal environment for relaxation and mental well-being.
              </p>
            </div>
          </div>
          <div className="enterprise-subservice-card-div" style={{ "--clr": "#FFD700" }}>
            <div className="enterprise-subservice-card-img-box">
              <img src={court} alt="Multipurpose Court" />
            </div>
            <div className="enterprise-subservice-card-div-content">
              <h2 className="enterprise-subservice-card-div-heading">Multipurpose Court</h2>
              <p className="enterprise-subservice-card-div-description">
                A versatile court that can be used for a variety of sports and activities, designed to cater to the recreational needs of residents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Amenities;
