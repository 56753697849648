import React from "react";
import "./ManufacturingBackground.css";
import video from "../src/asset/HBRealty-The-Gateway-Final.mp4";
import ManufacturingLanding from "../src/asset/HB Realty The Gateway - Banner 1.jpg";
import ManufacturingLanding2 from "../src/asset/HB Realty The Gateway - Banner 2.jpg";
import ManufacturingLandingMobile from "../src/asset/mobileimage.jpg";
import ManufacturingLandingMobile2 from "../src/asset/mobileimage1.jpg";

const ManufacturingBackground = ({ playStatus, heroCount }) => {
  const isMobile = window.innerWidth <= 768; // Detect mobile screen size

  if (playStatus) {
    return (
      <video className="ManufacturingBackground fade-in" autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
    );
  } else if (isMobile) {
    // Mobile images
    if (heroCount === 0) {
      return (
        <img
          src={ManufacturingLandingMobile}
          className="ManufacturingBackground fade-in"
          alt="Mobile Hero Image 1"
        />
      );
    } else if (heroCount === 1) {
      return (
        <img
          src={ManufacturingLandingMobile2}
          className="ManufacturingBackground fade-in"
          alt="Mobile Hero Image 2"
        />
      );
    }
  } else {
    // Desktop images
    if (heroCount === 0) {
      return (
        <img
          src={ManufacturingLanding}
          className="ManufacturingBackground fade-in"
          alt="Hero Image 1"
        />
      );
    } else if (heroCount === 1) {
      return (
        <img
          src={ManufacturingLanding2}
          className="ManufacturingBackground fade-in"
          alt="Hero Image 2"
        />
      );
    }
  }
};

export default ManufacturingBackground;
