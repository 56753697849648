import React from 'react';
import './connectivity.css';

const Services = () => {
    return (
        <div className="container-fluid py-5">
            <section id='connectivity'>
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <div className="mb-3">
                            <h6 className="text-primary text-uppercase" style={{ color: '#147c56' }}>
                                {/* Services */}
                            </h6>
                            <h1 style={{
                                fontFamily: "'Georgia', 'Times New Roman', serif", 
                                fontSize: '32px', 
                                fontWeight: 'bold', 
                                color: '#873e23', 
                                textAlign: 'center', 
                                letterSpacing: '1px', 
                                marginBottom: '15px' 
                            }}>
                                Connectivity
                                
                                    <span style={{
                                        display: 'block',
                                        width: '200px',
                                        height: '2px',
                                        backgroundColor: '#d1b584',
                                        margin: '10px auto 0',
                                        borderRadius: '2px'
                                      }}></span>
                                
                            </h1>
                        </div>
                        <p className="mb-4">
                            Our Property is strategically located near major highways, a metro station, and the airport, offering excellent accessibility and convenience for both local and international travel.
                        </p>
                        {/* <a href="about.html" className="btnContactUS btn-warning text-capitalize w-100">
                            Learn More
                        </a> */}
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                <path d="M184,24H72A32,32,0,0,0,40,56V184a32,32,0,0,0,32,32h8L65.6,235.2a8,8,0,1,0,12.8,9.6L100,216h56l21.6,28.8a8,8,0,1,0,12.8-9.6L176,216h8a32,32,0,0,0,32-32V56A32,32,0,0,0,184,24ZM56,120V80h64v40Zm80-40h64v40H136ZM72,40H184a16,16,0,0,1,16,16v8H56V56A16,16,0,0,1,72,40ZM184,200H72a16,16,0,0,1-16-16V136H200v48A16,16,0,0,1,184,200ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z"></path>
                            </svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>Metro Station</h4>
                                <p className="mb-0">
                                    Located just 2 minutes from the Pandhar metro Station.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">
                                <path d="M232,192H200V168h24a8,8,0,0,0,7.76-9.94l-32-128a8,8,0,0,0-15.52,0l-32,128A8,8,0,0,0,160,168h24v24H120V176h8a8,8,0,0,0,0-16h-8V144h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16h8v16H40a8,8,0,0,0,0,16h8v16H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16ZM192,65l21.75,87h-43.5ZM64,144h40v16H64Zm0,32h40v16H64Zm52-80A28,28,0,1,0,88,68,28,28,0,0,0,116,96Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,116,56Z"></path>
                            </svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>Central Park</h4>
                                <p className="mb-0">
                                    Only 10 minutes from Central Park and Iskcon temple.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000000" viewBox="0 0 256 256">
                                <path d="M235.92,199A8,8,0,0,1,225,195.92L155.32,72H136v8a8,8,0,0,1-16,0V72H100.68L31,195.92A8,8,0,0,1,17,188.08L82.32,72H24a8,8,0,0,1,0-16H232a8,8,0,0,1,0,16H173.68L239,188.08A8,8,0,0,1,235.92,199ZM128,112a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V120A8,8,0,0,0,128,112Zm0,56a8,8,0,0,0-8,8v16a8,8,0,0,0,16,0V176A8,8,0,0,0,128,168Z"></path>
                            </svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>Highways</h4>
                                <p className="mb-0">
                                    Seamlessly connected to Mumbai-Pune Expressway and Kalyan Shilphata Road.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000000" viewBox="0 0 256 256">
                                <path d="M240,208h-8V88a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8v40H104V40a8,8,0,0,0-8-8H32a8,8,0,0,0-8,8V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM168,96h48V208H168Zm-16,48v64H104V144ZM40,48H88V208H40ZM72,72V88a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Zm0,48v16a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm0,48v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm48,16V168a8,8,0,0,1,16,0v16a8,8,0,0,1-16,0Zm64,0V168a8,8,0,0,1,16,0v16a8,8,0,0,1-16,0Zm0-48V120a8,8,0,0,1,16,0v16a8,8,0,0,1-16,0Z"></path>
                            </svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>Navi Mumbai Corporate Park</h4>
                                <p className="mb-0">
                                    Just 10 minutes from NMCP.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000000" viewBox="0 0 256 256">
                                <path d="M235.58,128.84,160,91.06V48a32,32,0,0,0-64,0V91.06L20.42,128.84A8,8,0,0,0,16,136v32a8,8,0,0,0,9.57,7.84L96,161.76v18.93L82.34,194.34A8,8,0,0,0,80,200v32a8,8,0,0,0,11,7.43l37-14.81,37,14.81A8,8,0,0,0,176,232V200a8,8,0,0,0-2.34-5.66L160,180.69V161.76l70.43,14.08A8,8,0,0,0,240,168V136A8,8,0,0,0,235.58,128.84ZM224,158.24l-70.43-14.08A8,8,0,0,0,144,152v32a8,8,0,0,0,2.34,5.66L160,203.31v16.87l-29-11.61a8,8,0,0,0-5.94,0L96,220.18V203.31l13.66-13.65A8,8,0,0,0,112,184V152a8,8,0,0,0-9.57-7.84L32,158.24v-17.3l75.58-37.78A8,8,0,0,0,112,96V48a16,16,0,0,1,32,0V96a8,8,0,0,0,4.42,7.16L224,140.94Z"></path>
                            </svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>International Airport</h4>
                                <p className="mb-0">
                                    20 minutes from the international airport.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="#000000" viewBox="0 0 256 256"><path d="M184,24H72A32,32,0,0,0,40,56V184a32,32,0,0,0,32,32h8L65.6,235.2a8,8,0,1,0,12.8,9.6L100,216h56l21.6,28.8a8,8,0,1,0,12.8-9.6L176,216h8a32,32,0,0,0,32-32V56A32,32,0,0,0,184,24ZM56,120V80h64v40Zm80-40h64v40H136ZM72,40H184a16,16,0,0,1,16,16v8H56V56A16,16,0,0,1,72,40ZM184,200H72a16,16,0,0,1-16-16V136H200v48A16,16,0,0,1,184,200ZM96,172a12,12,0,1,1-12-12A12,12,0,0,1,96,172Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,184,172Z"></path></svg>                                <h4>Taloja Railway Station</h4>
                                <p className="mb-0">
                                    5 minutes from the property.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="#000000" viewBox="0 0 256 256"><path d="M232,160H200V101.34a71.89,71.89,0,0,0,29,21.42,8,8,0,0,0,6-14.83A55.78,55.78,0,0,1,200,56a8,8,0,0,0-16,0A56,56,0,0,1,72,56a8,8,0,0,0-16,0,55.78,55.78,0,0,1-35,51.93,8,8,0,0,0,6,14.83,71.89,71.89,0,0,0,29-21.42V160H24a8,8,0,0,0,0,16H56v24a8,8,0,0,0,16,0V176H184v24a8,8,0,0,0,16,0V176h32a8,8,0,0,0,0-16Zm-88-33.8V160H112V126.2a72,72,0,0,0,32,0Zm-72-25a72.47,72.47,0,0,0,24,19.27V160H72ZM160,160V120.48a72.47,72.47,0,0,0,24-19.27V160Z"></path></svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>MTHL connectivity</h4>
                                <p className="mb-0">
                                    25 minutes from the property.
                                </p>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="service-item bg-light text-center p-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="#000000" viewBox="0 0 256 256"><path d="M176,100a12,12,0,1,1-12-12A12,12,0,0,1,176,100Zm-44,20a12,12,0,1,0,12,12A12,12,0,0,0,132,120Zm84-24A88,88,0,1,1,128,8,88.1,88.1,0,0,1,216,96Zm-16,0a72,72,0,1,0-72,72A72.08,72.08,0,0,0,200,96Zm-34.86,96.53C152,197.56,139.85,200,128,200s-24-2.44-37.14-7.47a8,8,0,1,0-5.72,14.94A125.91,125.91,0,0,0,120,215.68V248a8,8,0,0,0,16,0V215.68a125.91,125.91,0,0,0,34.86-8.21,8,8,0,1,0-5.72-14.94Z"></path></svg>
                            <a href="product.html" style={{ textDecoration: 'none', color: '#000' }} id="service">
                                <h4>Golf Court</h4>
                                <p className="mb-0">
                                    25 minutes from the property.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
    );
};

export default Services;
