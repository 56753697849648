import React from 'react';
import './footer.css'; // Assuming you'll create a separate CSS file for the footer
import QR1 from '../src/asset/QR1.png';
import QR2 from '../src/asset/QR2.png';
import GatewayLogo from '../src/asset/GatewayLogo.jpeg';

const Footer = () => {
    return (
        <footer className="contact-footer">
            {/* Website Logo Section */}
            <div className="contact-footer-logo">
                <img src={GatewayLogo} alt="Gateway Logo" />
            </div>

            {/* Divider */}
            <div className="contact-footer-divider"></div>

            {/* QR Codes and Details Section */}
            <div className="contact-footer-details">
                <div className="qr-codes">
                    <img src={QR1} alt="QR Code 1" />
                    <img src={QR2} alt="QR Code 2" />
                </div>
                <div className="contact-footer-text">
                    <p>MahaRERA No.: P52000045672 & P52000055464</p>
                    <p>For detailed disclaimers. Please examine all the document of the developer on the website of RERA at https://maharera.mahaonline.gov.in. <br></br>The Project is registered in the name of ‘Nandanvan’ and ‘Nandanvan Phase 2’.Tower No.1 & 2 Reg No.: P52000045672 &  <br></br>Tower No.3 Reg No.: P52000055464.</p>
                </div>
            </div>

            {/* Disclaimer Section */}
            <div className="contact-footer-disclaimer">
                <p>Disclaimer: The plans, specifications, images and other details herein are only indicative and the developer/owner reserve their rights to change any or all of these in the interest of the development. This printed material does not constitute an offer and/or contract of any type between the developer/owner and the recipient. The purchase lessee of this development shall be governed by the terms and conditions of the agreement for sale/lease entered into between parties and no details mentioned in this printed material shall in anyway govern such transaction. *T&C Apply. Scheme is subject to home loan eligibility and approval. This does not constitute an offer to sale. Developer at his own discretion may withdraw the scheme at any given time without notice . Distances as per google traffic conditions.</p>
            </div>
        </footer>
    );
};

export default Footer;