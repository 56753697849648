import React from 'react';
import './configuration.css'; // Assuming you save your CSS in a separate file

const Courses = () => {
  const courses = [
    {
      title: '1 BHK  - Carpet Area - 388 sq.ft',
      date: '36.99 Lac++',
    },
    {
      title: '2 BHK - Carpet Area - 552 sq.ft',
      date: '50.74 Lac++',
    },
  ];

  return (
    <div className="ag-format-container">
      <section id='configurations'>
      <h2 style={{
         fontFamily: "'Georgia', 'Times New Roman', serif", 
         fontSize: '32px', 
         fontWeight: 'bold', 
         color: '#873e23', 
         textAlign: 'center', 
         letterSpacing: '1px', 
         marginBottom: '15px' 
      }}>
        Configurations
        <span style={{
          content: "''",
          position: 'absolute',
          display: 'block',
          marginTop: '10px',
          width: '250px', // Adjusted width for longer line
          height: '2px',
          backgroundColor: '#d1b584',
          left: '50%',
          transform: 'translateX(-50%)',
        }}></span>
      </h2>
      <div className="ag-courses_box">
        {courses.map((course, index) => (
          <div className="ag-courses_item" key={index}>
            <a href="#" className="ag-courses-item_link">
              <div className="ag-courses-item_bg"></div>
              <div className="ag-courses-item_title" >  {/* Gold text color */}
                {course.title}
              </div>
              {course.date && (
                <div className="ag-courses-item_date-box">
                  Start:
                  <span className="ag-courses-item_date" > {/* Gold text color */}
                    {course.date}
                  </span>
                </div>
              )}
            </a>
          </div>
        ))}
      </div>
      </section>    
    </div>
  );
};

export default Courses;
