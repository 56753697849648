import React, { useState, useEffect } from 'react';
import ManufacturingBackground from './ManufacturingBackground'; // Adjust the path if needed
import ManufacturingHero from './ManufacturingHero'; // Adjust the path if needed

const Home = () => {
  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(false);

  // Data for hero section
  const heroData = [
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
    { text1: "Lorem Ipsum", text2: " is simply dummy text" },
  ];

  // Set up interval to update heroCount
  useEffect(() => {
    const interval = setInterval(() => {
      setHeroCount((count) => (count === 1 ? 0 : count + 1));
    }, 3000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
<section id="home" style={{ paddingTop: '50px' }}>
<ManufacturingBackground playStatus={playStatus} heroCount={heroCount} />
      <ManufacturingHero
        setPlayStatus={setPlayStatus}
        heroData={heroData}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
      />
    </section>
  );
};

// const styles = {
//   section: {
//     // padding: '50px',
//     backgroundColor: '#f4f4f4',
//     color: 'red',
//     marginBottom: '20px',
//   },
// };

export default Home;
