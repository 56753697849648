import React, { useState, useEffect } from 'react';
import './ContactPopup.css';

const ContactPopup = ({ onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
    });

    const [errors, setErrors] = useState({
        phone: '',
    });

    const [formValid, setFormValid] = useState(false);
    const [showPopup, setShowPopup] = useState(true);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success popup

    const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeYRxM1M2qkUbpnZIdrWhyHJrj9PJ641vFIlgoq0r_DOBigbw/formResponse';

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Validate the form
    const validateForm = () => {
        const phoneRegex = /^[0-9]{10}$/;
        let newErrors = { ...errors };

        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Phone number must be 10 digits.';
        } else {
            newErrors.phone = '';
        }

        setErrors(newErrors);

        // Check if the form is valid
        const isFormValid = formData.name && phoneRegex.test(formData.phone);
        setFormValid(isFormValid);
    };

    // UseEffect to validate form when formData changes
    useEffect(() => {
        validateForm();
    }, [formData]);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formValid) {
            const formURL = `${GOOGLE_FORM_URL}?entry.1659917404=${encodeURIComponent(formData.name)}&entry.1895354621=${encodeURIComponent(formData.phone)}`;

            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = formURL;
            document.body.appendChild(iframe);

            // Show the success popup
            setShowSuccessPopup(true);

            // Reset form data
            setFormData({
                name: '',
                phone: ''
            });

            // Close the original popup
            setShowPopup(false);
        } else {
            alert('Please fill out all fields correctly.');
        }
    };

    // Close the success popup
    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
        onClose(); // Close the parent popup
    };

    return (
        <>
            {showPopup && (
                <div className="contact-popup">
                    <div className="contact-popup-content">
                        <button className="popup-close-button" onClick={() => setShowPopup(false)}>✕</button>
                        <h2 style={{
                            fontSize: '1.2rem',
                            fontWeight: 'normal',
                            color: '#000000',
                            margin: '0',
                            padding: '10px',
                            textAlign: 'center'
                        }}>
                            Help Us Contact You
                        </h2>

                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Your Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                maxLength="10"
                                required
                            />
                            {errors.phone && <p className="error">{errors.phone}</p>}
                            <button className="popup-submit" type="submit" disabled={!formValid}>
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {/* Success Popup */}
            {showSuccessPopup && (
                <div className="success-popup">
                    <div className="popup-content">
                        <span className="close-btn" onClick={closeSuccessPopup}>&times;</span>
                        <h3>Message Received!</h3>
                        <p>We have received your message. We will get in touch with you shortly.</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactPopup;
