import React, { useState } from 'react';
import './gallery.css';
import gallerysmall from '../src/asset/gallery1.jpg';
import gallerysmall1 from '../src/asset/gallery2.jpg';
import bigimage from '../src/asset/biggallery.jpg';

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <div className="gallery-container">
      <section className='gallery'>
      <h1 style={{
        fontFamily: "'Georgia', 'Times New Roman', serif", 
        fontSize: '32px', 
        fontWeight: 'bold', 
        color: '#873e23', 
        textAlign: 'center', 
        letterSpacing: '1px', 
        marginBottom: '15px' 
      }}>
        Property Vista
      </h1>
      <div className="gallery-grid">
        <div className="gallery-item big-image">
          <img
            src={gallerysmall1}
            alt="Big"
            onClick={() => openModal(gallerysmall1)}
          />
        </div>
        <div className="gallery-item small-image">
          <img
            src={gallerysmall}
            alt="Small 1"
            onClick={() => openModal(gallerysmall)}
          />
        </div>
        <div className="gallery-item small-image1">
          <img
            src={bigimage}
            alt="Small 2"
            onClick={() => openModal(bigimage)}
          />
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={currentImage} alt="Expanded" />
            <button className="close-button" onClick={closeModal}>X</button>
          </div>
        </div>
      )}
      </section>
    </div>
  );
};

export default Gallery;
