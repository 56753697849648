import React, { useState } from 'react';
import './BrochureButton.css';
import DownloadPopup from './DownloadPopup';

const BrochureButton = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleButtonClick = () => {
        setShowPopup(true);
    };

    return (
        <>
            <div className="brochure-button" onClick={handleButtonClick}>
                Download Brochure
            </div>
            {showPopup && <DownloadPopup onClose={() => setShowPopup(false)} />}
        </>
    );
};

export default BrochureButton;