import React from 'react';
import './AboutUs.css'; // Import the CSS file
import yourImage from '../src/asset/aboutus1.jpg'; // Adjust the path based on your folder structure

const AboutUs = () => {
  return (
    <section id="aboutus" className="about-us-section">
      <div className="about-us-container">
        <div className="about-us-image">
          <img src={yourImage} alt="About Us" />
        </div>
        <div className="about-us-content">
        <div class="about-section">
        <h1 style={{ 
  fontFamily: "'Georgia', 'Times New Roman', serif", 
  fontSize: '32px', 
  fontWeight: 'bold', 
  color: '#873e23', 
  textAlign: 'center', 
  letterSpacing: '1px', 
  marginBottom: '15px' 
}}>
  About us
  <span style={{
            display: 'block',
            width: '160px',
            height: '2px',
            backgroundColor: '#d1b584',
            margin: '10px auto 0',
            borderRadius: '2px'
          }}></span>
</h1>

          
          <div className='aboutext'>
          <p>
            Welcome to HB Realty, where we strive to create extraordinary living spaces designed to elevate your lifestyle. Our latest project, The Gateway, represents a new era in modern living—an elegant residency building nestled in the heart of the city, combining convenience, luxury, and connectivity.
          </p>
          <p>
            At HB Realty, we understand the importance of accessibility. The Gateway is strategically located with seamless road access and is just a short walk away from the metro station, ensuring effortless travel throughout the city. For frequent travelers, the airport is conveniently situated just 20 minutes away, making it an ideal choice for professionals and families alike.
          </p>
          </div>
          </div>
          {/* Project Highlights Section */}
          <h2>Project Highlights</h2>
          <ul className="project-highlights">
            <li>Panvel Municipal Corporation Approved</li>
            <li>G+10 Storey Residential + Commercial</li>
            <li>Walkable Bus Stop & Metro Station</li>
            <li>20 Mins Away from Kharghar Station</li>
            <li>Spread Over 2.5 Acres</li>
            <li>Highest Levels of Safety and Security</li>
            <li>G+2 Storied Club House Amenities</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
