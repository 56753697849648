import React from 'react';
import Header from './header'; // Adjust the path if needed
import Home from './Home';
import AboutUs from './AboutUs';
import Configurations from './Configurations';
import Amenities from './Amenities';
import Gallery from './Gallery';
import Contact from './Contact';
import Connectivity from './connectivity';
import ContactPopup from './ContactPopup';
import BrochureButton from './BrochureButton';
import DownloadPopup from './DownloadPopup';
import MouseFollow from './MouseFollow';
import Whatsapp from './whatsapp';
import Footer from './footer';
const App = () => {

  return (
    <div>
      <Header />
      <Home />
      <AboutUs />
      <Configurations />
      <Amenities />
      <Connectivity />
      <Gallery />
      <Contact />
      <ContactPopup />
      <BrochureButton />
      <Footer />
      {/* <DownloadPopup /> */}
      {/* <MouseFollow /> */}

      {/* <Whatsapp /> */}
    </div>
  );
};

export default App;
