import React from "react";
import "./ManufacturingHero.css";
import play_icon from "../src/asset/play_icon.png";
import pause_icon from "../src/asset/pause_icon.png";

const ManufacturingHero = ({
  heroData,
  setHeroCount,
  heroCount,
  setPlayStatus,
  playStatus,
}) => {
  return (
    <div className="ManufacturingHero">
      <div className="ManufacturingHero-text">
        <p>{heroData.text1}</p>
        <p>{heroData.text2}</p>
      </div>
      
    </div>
  );
};

export default ManufacturingHero;
