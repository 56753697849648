import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';  // Import Link from react-scroll
import './header.css';
import logo from '../src/asset/gateway.png';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to track menu toggle

  // Detect scroll event and update isScrolled state
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      {/* Small Header */}
     
      {/* Main Navigation */}
      <nav className={`custom-header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="logo" style={{ width: '200px' }}>
      <img src={logo} alt="Logo" />
        </div>

        <ul className={`links ${menuOpen ? 'show' : ''}`}>
          <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
          <li><Link to="aboutus" smooth={true} duration={500}>About Us</Link></li>
          <li><Link to="configurations" smooth={true} duration={500}>Configurations</Link></li>
          <li><Link to="amenities" smooth={true} duration={500}>Amenities</Link></li>
          <li><Link to="connectivity" smooth={true} duration={500}>Connectivity</Link></li>
          <li><Link to="gallery" smooth={true} duration={500}>Gallery</Link></li>
          <li><Link to="contact" smooth={true} duration={500}>Contact Us</Link></li>
        </ul>

        {/* Hamburger Menu Icon */}
        <div className="icon-burger" onClick={() => setMenuOpen(!menuOpen)}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </nav>

      {/* Page Content */}
     
    </header>
  );
};

export default Header;
